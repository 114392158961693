import { Pages }            from "./Pages";
import ReactDOM             from "react-dom";
import React                from "react";
import {CertificateSection} from "../components/CertificateSection";



export class CertificatesDetail extends Pages {

    initPageHandlers() {
        super.initPageHandlers();
        this.data = window.diaryJsData;
        ReactDOM.render(
            <CertificateSection
                certificate={this.data.element}/>,
            document.getElementById('certificateDetail')
        );

    }
}
